
import { Component, Vue } from "vue-property-decorator";
import { userStore } from "@/store/modules/user";
import { IProfile } from "@/store/models/user";
import { Dropdown } from "@/components";
import { isNestle } from "@/constant/platform";

@Component({
  name: "ProfileView",
  components: {
    Dropdown
  }
})
export default class ProfileView extends Vue {
  isAnyActiveOrder = false;
  countryCode = "+60";
  dropdown = {
    deliveryAddress: false,
    vehicle: false
  };
  isNestleMerchant = false;

  get appVersion() {
    return process.env.VUE_APP_VERSION;
  }
  get userStore() {
    return userStore;
  }
  get profile() {
    return userStore.profile;
  }
  isNestle() {
    return isNestle();
  }
  setIsNestleMerchant(isChecked) {
    this.isNestleMerchant = isChecked;
    if (!isChecked && this.profile) {
      this.profile.nestleMerchantID = "";
    }
  }
  removeDeliveryAddress(primaryAddress) {
    if (this.profile?.addresses) {
      this.profile?.addresses.splice(primaryAddress, 1);
      if (primaryAddress === this.profile.primaryAddress) {
        this.profile.primaryAddress = 0;
      }
    }
  }
  removeVehicleNo(primaryVehicle) {
    if (this.profile?.vehicles) {
      this.profile?.vehicles.splice(primaryVehicle, 1);
      if (primaryVehicle === this.profile.primaryVehicle) {
        this.profile.primaryVehicle = 0;
      }
    }
  }
  async saveProfile() {
    if (this.canSave) {
      await userStore.updateProfile(userStore.profile as IProfile);
      const { redirect } = this.$route.query as { redirect: string };

      if (redirect) {
        if (redirect == "GO_BACK") {
          this.$router.go(-2);
        } else {
          this.$router.replace({ name: redirect });
        }
      } else {
        this.$router.go(-1);
      }
    }
  }

  logout() {
    userStore.logout();
  }

  get canSave() {
    if (this.isNameValid && this.isEmailValid) return true;
    return false;
  }

  get isNameValid() {
    if (this.profile?.name && this.profile?.name.trim() !== "") {
      return true;
    }
    return false;
  }

  get isEmailValid() {
    if (/.+@.+\..+/.test(String(this.profile?.email).toLowerCase()))
      return true;
    return false;
  }

  get isBoost() {
    return userStore.user.boostCustomerId != "";
  }

  activated() {
    if (this.profile?.nestleMerchantID != "") {
      this.isNestleMerchant = true;
    }
  }
}
