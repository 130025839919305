
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from "vue-property-decorator";
import { userStore } from "@/store/modules/user";
import { merchantStore } from "@/store/modules/merchant";
import { IAddress } from "@/store/models/user";
import { uiStore } from "@/store/modules/ui";

@Component({
  components: {}
})
export default class LoginView extends Vue {
  viewPhoto = false;
  placePhoto = "";
  markers: { lat; lng }[] = [];
  center = { lat: 0, lng: 0 };
  mode = "new";
  remark = false;
  invalidAddr = false;
  addressType = ["Home", "Office", "Other"];
  addr: IAddress | null = {
    name: "Home",
    addressLine1: "",
    addressLine2: "",
    postcode: "",
    city: "",
    state: "",
    country: "",
    unit: "",
    remark: "",
    lat: 0,
    lng: 0
  };
  hasQuery = false;
  results: any = [];
  sessionToken;
  capturedAddress = "";
  get isWeb() {
    return merchantStore.isWeb;
  }

  get user() {
    return userStore.user;
  }
  get canSave() {
    if (this.invalidAddr) return false;
    if (
      this.addr?.addressLine1 &&
      this.addr?.lat &&
      this.addr?.lng &&
      this.addr?.unit &&
      this.addr?.name
    )
      return true;
    else return false;
  }

  filterAddress(result, column) {
    const x = result.address_components.filter((each) =>
      each.types.includes(column)
    );
    if (x.length > 0) {
      return x[0].long_name.trim();
    }
    return "";
  }

  addRemark() {
    this.remark = !this.remark;
  }
  setPlace(place, input) {
    this.invalidAddr = false;
    const route = this.filterAddress(place, "route");
    const addressLine2 = "";
    let unit = this.filterAddress(place, "street_number");
    const postcode = this.filterAddress(place, "postal_code");
    const city = this.filterAddress(place, "locality");
    const state = this.filterAddress(place, "administrative_area_level_1")
      .replace("Negeri", "")
      .replace("Wilayah Persekutuan", "")
      .trim();
    const country = this.filterAddress(place, "country");
    let formattedAddress = !place.formatted_address.includes(place.name)
      ? place.name + ", " + place.formatted_address
      : place.formatted_address;
    let addressLine1 = formattedAddress
      .split(", ")
      .map((each) => {
        return each
          .trim()
          .replace("Federal Territory of", "")
          .replace(/, /g, "")
          .trim();
      })
      .filter((each) => {
        let valid =
          (postcode.length > 0 ? !each.includes(postcode) : true) &&
          (city.length > 0 ? !each.includes(city) : true) &&
          (state.length > 0 ? !each.includes(state) : true) &&
          (country.length > 0 ? !each.includes(country) : true);
        return valid;
      });

    if (addressLine1.length == 0 && route.length > 0) {
      //sometimes the street name contains the city name, and it won't pass the previosu filter
      addressLine1.push(route);
    }
    if (addressLine1.length > 0) {
      let addrL1 = addressLine1.join(", ");
      //any place name that's higher than route level can be added in the beginning
      if (!addrL1.includes(place.name)) addrL1 = place.name + ", " + addrL1;
      // this.markers[0] = { lat: addr.lat, lng: addr.lng };
      // this.center = this.markers[0];
      const addr = {
        ...this.addr,
        addressLine1: addrL1,
        addressLine2,
        postcode,
        city,
        state,
        country,
        unit,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      } as IAddress;
      this.addr = addr;
      this.capturedAddress = `${
        this.addr?.addressLine1 ? this.addr?.addressLine1 + ", " : ""
      }${this.addr?.postcode ? this.addr?.postcode + ", " : ""}${
        this.addr?.state ? this.addr?.state + ", " : ""
      }${this.addr?.city ? this.addr?.city + ", " : ""}${this.addr?.country}`;

      //whether it's a premis, establsihment, route .. as long as the unit is not defined esure they give
      //more details, especially if addressLine1 is only a street name(route), then the customer has to define
      //his building / unit
      if (!unit || !route) {
        this.remark = true;
      }
      const place_type = place.types.filter(
        (t) => t === "establishment" || t === "premise"
      );
      if (place_type.length > 0) this.remark = true;
      //sometimes, even if google maps suggestion show that it's giving unit no, it doesn't pass it, that's why
      //we need to show the customer only capturedAddress
    } else {
      this.invalidAddr = true;
    }
    this.hasQuery = false;
  }

  async saveAddress() {
    if (this.addr && this.user.profile) {
      console.log("addr", this.addr);
      // add unit no. in the beginning if available
      let arr = this.addr.addressLine1.split(",").map((add) => add.trim());
      if (this.addr.unit && !arr.includes(this.addr.unit)) {
        this.addr.addressLine1 = this.addr.unit + ", " + this.addr.addressLine1;
      }
      if (this.user.profile.addresses) {
        this.user.profile.addresses.push(this.addr as IAddress);
      } else {
        this.user.profile.addresses = [];
        this.user.profile.addresses[0] = this.addr as IAddress;
      }
      this.user.profile.primaryAddress = this.user.profile.addresses.length - 1;

      uiStore.setLoader(true);
      await userStore.updateProfile(this.user.profile).finally(() => {
        uiStore.setLoader(false);
      });
      this.addr = {
        name: "Home",
        addressLine1: "",
        addressLine2: "",
        postcode: "",
        city: "",
        state: "",
        country: "",
        unit: "",
        remark: "",
        lat: 0,
        lng: 0
      };
      this.capturedAddress = "";
    }
    if (merchantStore.platform == "ALIPAY") {
      if (this.$route.query?.source == "indexPage") {
        this.$miniProgram.switchTab("/pages/index/index");
      } else {
        this.$miniProgram.switchTab("/pages/profile/profile");
      }
    }
    this.$router.go(-1);
  }

  onSelectSearchResult(result) {
    let request = {
      placeId: result.place_id
    };

    let gMap = new google.maps.Map(
      document.createElement("gmap-dropdown-list")
    );
    let service = new google.maps.places.PlacesService(gMap);
    service.getDetails(request, (place, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        const selectedPlace = this.setPlace(place, { unit: "" });
      }
    });
  }

  async onQueryAutocomplete(query) {
    if (query) {
      let autocompleteService = new google.maps.places.AutocompleteService();

      autocompleteService.getPlacePredictions(
        {
          input: query,
          sessionToken: this.sessionToken,
          componentRestrictions: { country: ["my", "sg"] }
        },
        (predictions, status) => {
          if (
            status != google.maps.places.PlacesServiceStatus.OK ||
            !predictions
          ) {
            // console.log("status", status);
            return;
          }
          // console.log("predictions", predictions);
          this.results = predictions;
        }
      );
    } else {
      this.results = [];
    }
  }
  activated() {
    // Create a new session token.
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  }
}
