
import { Component, Vue } from "vue-property-decorator";
import { IVehicle } from "@/store/models/user";
import { userStore } from "@/store/modules/user";
import { merchantStore } from "@/store/modules/merchant";
// import Cookies from "js-cookie";
import cookies from "@/utils/cookies";
import { uiStore } from "@/store/modules/ui";

@Component
export default class VehicleInfoView extends Vue {
  vehicleNo = "";
  vehicleDescription = "";
  hasExtraInfo = false;

  get user() {
    return userStore.user;
  }

  async saveInfo() {
    const vehicle = {
      plate: this.vehicleNo,
      model: this.vehicleDescription,
      type: "Default"
    } as IVehicle;
    if (this.vehicleNo !== "" && this.user.profile) {
      if (this.user.profile.vehicles) {
        this.user.profile.vehicles.push(vehicle);
        this.user.profile.primaryVehicle = this.user.profile.vehicles.length - 1;
      } else {
        this.user.profile.vehicles = [];
        this.user.profile.vehicles[0] = vehicle;
        this.user.profile.primaryVehicle = 0;
      }
      uiStore.setLoader(true);
      await userStore.updateProfile(this.user.profile).finally(() => {
        uiStore.setLoader(false);
      });
    }
    cookies.set("vehicleNo", this.vehicleNo.toUpperCase());
    cookies.set("vehicleDescription", this.vehicleDescription);
    if (merchantStore.platform == "ALIPAY") {
      this.$miniProgram.switchTab("/pages/profile/profile");
    }
    this.$router.go(-1);
  }

  async activated() {
    this.vehicleNo = (await cookies.get("vehicleNo")) || "";
    this.vehicleDescription = (await cookies.get("vehicleDescription")) || "";
    if (this.vehicleDescription) this.hasExtraInfo = true;
  }
}
